<template>
  <div class="space-y-6">
    <div>
      <div class="block w-full overflow-hidden border aspect-w-16 aspect-h-9">
        <img
          :src="asset.thumbnail"
          alt
          class="object-contain"
        >
      </div>
      <div class="flex items-start justify-between mt-4">
        <div>
          <h2 class="text-lg font-medium text-gray-900">
            <span class="sr-only">Details for</span>
            {{ asset.name }}
          </h2>
          <p class="text-sm font-medium text-gray-500">
            3.9 MB
          </p>
        </div>
        <button
          type="button"
          class="flex items-center justify-center w-8 h-8 ml-4 text-gray-400 bg-white rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <fa-icon :icon="['far', 'heart']" />

          <span class="sr-only">Favorite</span>
        </button>
      </div>

      <Sidebar :asset="asset" />
    </div>
  </div>
</template>
<script>
const Sidebar = () => import('@components/viewer/Sidebar')

export default {

  components: {
    Sidebar
  },
  props: {
    asset: {
      type: Object,
      required: true
    }
  }
}</script>
